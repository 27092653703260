import "./Experience.css";
import LanguageFrame from "./LanguageFrame";

// Helper function to format dates
const formatDate = (date) => {
    // Handle "present" case
    if (Number.isNaN(date) || !date || date == "NaN" || date.toLowerCase() === "present") {
        return "PRESENT";
    }

    // Check for correct format MM/YYYY
    const dateParts = date.split("/");
    if (dateParts.length !== 2) {
        return "ERR_FORMAT";
    }

    const [month, year] = dateParts;
    const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];

    // Validate month and year
    const monthIndex = parseInt(month, 10);
    if (isNaN(monthIndex) || monthIndex < 1 || monthIndex > 12 || isNaN(parseInt(year, 10))) {
        return "ERR_FORMAT";
    }

    const formattedMonth = months[monthIndex - 1];
    return `${formattedMonth} ${year}`;
};

function Experience(props) {
    // Sort languages in ascending alphabetical order
    const sortedLanguages = [...props.languages].sort();

    // Format startDate and endDate
    const formattedStartDate = formatDate(props.startDate);
    const formattedEndDate = formatDate(props.endDate);
    
    return (
        <>
            <a href={props.link} className="exp-outer-container">
                <div className="exp-left-container">
                    {formattedStartDate} — <br className="date-break"/>
                    {formattedEndDate}
                </div>

                <div className="exp-right-container">
                    <div className="exp-role-header-container">
                        <h1 className="exp-role-header">{props.role}</h1>
                        <svg className="exp-arrow-svg" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.44403 0.47296L5.87709 2.90602L0.451374 8.33173L1.65574 9.57259L7.0997 4.12863L9.52667 6.5556V0.47296L3.44403 0.47296Z" fill="#E2E8F0"/>
                        </svg>
                    </div>

                    <div className="exp-role">
                        {props.team} · {props.company}
                    </div>

                    <ul className="exp-details">
                        {props.details.map((detail, index) => (
                            <li key={index}>{detail}</li>
                        ))}
                    </ul>

                    <div className="lang-container">
                        {sortedLanguages.map((language, index) => (
                            <LanguageFrame key={index} text={language} />
                        ))}
                    </div>
                </div>
            </a>
        </>
    );
}

export default Experience;
