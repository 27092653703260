import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

fetch('../php/db.php') // Call connect.php in the same directory
  .then((response) => {
    if (!response.ok) {
      const importedJsonResponse = {
        response: false,                                    // Failure indicator
        ERROR: `HTTP error! Status: ${response.status}`,    // Error message
        DATA: []                                            // Default empty data
      };
  
      // Render the React App and pass the error object as a prop
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <React.StrictMode>
          <App importedJsonResponse={importedJsonResponse} />
        </React.StrictMode>
      );
    }
    return response.json();
  })
  .then((data) => {
    // console.log('Data returned from PHP:', data);

    // Create an object to pass into the App component
    const importedJsonResponse = {
      response: true,       // Success indicator
      ERROR: null,          // No error
      DATA: data            // Data from PHP
    };

    // Render the React App and pass the object as a prop
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App importedJsonResponse={importedJsonResponse} />
      </React.StrictMode>
    );
  })
  .catch((error) => {
    console.error('Error fetching data from PHP:', error);

    // Create an error object to pass into the App component
    const importedJsonResponse = {
      response: false,        // Failure indicator
      ERROR: error.message,   // Error message
      DATA: []                // Default empty data
    };

    // Render the React App and pass the error object as a prop
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App importedJsonResponse={importedJsonResponse} />
      </React.StrictMode>
    );
  });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
