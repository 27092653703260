import './App.css';
import HeaderTab from './tabs/HeaderTab';
import AboutMeTab from './tabs/AboutMeTab'
import ExperienceTab from './tabs/ExperienceTab';
import FooterTab from './tabs/FooterTab';
import ProjectsTab from './tabs/ProjectsTab';
import RadialGradientBgEffect from './hooks/RadialGradientBgEffect';
import ExtractJson from './hooks/ExtractJson';

function App({ importedJsonResponse }) {
  // Call the radial gradient background effect function
  RadialGradientBgEffect();

  const extractedJson = ExtractJson(importedJsonResponse.DATA);
  
  // Get latest job
  var currentRole = "NaN";
  if (extractedJson.EXPERIENCES_LIST && extractedJson.EXPERIENCES_LIST.length > 0) {
    // Get the last experience object
    const currentExperience = extractedJson.EXPERIENCES_LIST[extractedJson.EXPERIENCES_LIST.length - 1];
    // Extract the role from the last experience object
    currentRole = currentExperience.role;
  } 

  // DISABLE WHEN TESTING 
  if (importedJsonResponse.ERROR) return (
    <>
      <div className='on-load-container'>
        <div className='error-caption'>Error loading data: {importedJsonResponse.ERROR}</div>
      </div>
    </>
  );

  if (extractedJson.ERROR) return (
    <>
      <div className='on-load-container'>
        <div className='error-caption'>Error loading data: {extractedJson.ERROR}</div>
      </div>
    </>
  );
  
  return (
    <>
      <div className = "outer-container">
        <HeaderTab 
        firstName = {extractedJson.FIRST_NAME} 
        lastName = {extractedJson.LAST_NAME}
        currentJob = {currentRole}
        headerQuote = {extractedJson.HEADER_QUOTE}
        GitHubLink = {extractedJson.GITHUB_LINK}
        LinkedinLink = {extractedJson.LINKEDIN_LINK}
        InstagramLink = {extractedJson.INSTAGRAM_LINK}
        MailLink = {extractedJson.MAIL_LINK}
        />
        
        <div className='right-container'>
          <AboutMeTab personalQuote = {extractedJson.PERSONAL_QUOTE}/>

          <ExperienceTab experienceList = {extractedJson.EXPERIENCES_LIST} resumeLink = {extractedJson.RESUME_LINK}/>

          <ProjectsTab projectList = {extractedJson.PROJECTS_LIST}/>
          
          <FooterTab />
        </div>
      </div>
    </>
  );
}

export default App;
