const ExtractJson = (jsonData) => {
    // Ensure the data is not null or undefined and unwrap it if it's an array
    const data = Array.isArray(jsonData) && jsonData.length > 0 ? jsonData[0] : jsonData;

    // Extract variables with fallback values
    const FIRST_NAME = data?.first_name || 'NULL';
    const LAST_NAME = data?.last_name || 'NULL';
    const HEADER_QUOTE = data?.header_quote || 'NULL';
    const PERSONAL_QUOTE = data?.personal_quote || 'NULL';
    const EXPERIENCES_LIST = data?.experiences || [];
    const RESUME_LINK = data?.resume_link || 'NULL';
    const PROJECTS_LIST = data?.projects || [];
    const GITHUB_LINK = data?.github_link || 'NULL';
    const LINKEDIN_LINK = data?.linkedin_link || 'NULL';
    const INSTAGRAM_LINK = data?.instagram_link || 'NULL';
    const MAIL_LINK = data?.mail_link || 'NULL';

    const missingDataString = 'Missing essential data';

    // Determine which data is missing
    const missingData = {
        DISPLAY_NAME: !(FIRST_NAME && LAST_NAME),
        HEADER_QUOTE: !HEADER_QUOTE,
        PERSONAL_QUOTE: !PERSONAL_QUOTE,
        EXPERIENCES_LIST: !(EXPERIENCES_LIST && EXPERIENCES_LIST.length > 0),
        RESUME_LINK: !RESUME_LINK,
        PROJECTS_LIST: !(PROJECTS_LIST && PROJECTS_LIST.length > 0),
        GITHUB_LINK: !GITHUB_LINK,
        LINKEDIN_LINK: !LINKEDIN_LINK,
        INSTAGRAM_LINK: !INSTAGRAM_LINK,
        MAIL_LINK: !MAIL_LINK
    };

    // Determine the error message based on missing data
    let ERROR = null;
    switch (true) {
        case missingData.DISPLAY_NAME:
            ERROR = missingDataString + " DISPLAY_NAME";
            break;
        case missingData.HEADER_QUOTE:
            ERROR = missingDataString + " HEADER_QUOTE";
            break;
        case missingData.PERSONAL_QUOTE:
            ERROR = missingDataString + " PERSONAL_QUOTE";
            break;
        case missingData.EXPERIENCES_LIST:
            ERROR = missingDataString + " EXPERIENCES_LIST";
            break;
        case missingData.RESUME_LINK:
            ERROR = missingDataString + " RESUME_LINK";
            break;
        case missingData.PROJECTS_LIST:
            ERROR = missingDataString + " PROJECTS_LIST";
            break;
        case missingData.GITHUB_LINK:
            ERROR = missingDataString + " GITHUB_LINK";
            break;
        case missingData.LINKEDIN_LINK:
            ERROR = missingDataString + " LINKEDIN_LINK";
            break;
        case missingData.INSTAGRAM_LINK:
            ERROR = missingDataString + " INSTAGRAM_LINK";
            break;
        case missingData.MAIL_LINK:
            ERROR = missingDataString + " MAIL_LINK";
            break;
    }

    return { 
        ERROR, 
        FIRST_NAME, 
        LAST_NAME, 
        HEADER_QUOTE, 
        PERSONAL_QUOTE,
        GITHUB_LINK,
        LINKEDIN_LINK,
        INSTAGRAM_LINK,
        MAIL_LINK,
        EXPERIENCES_LIST, 
        RESUME_LINK, 
        PROJECTS_LIST
    };
};

export default ExtractJson;
